import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { tmdbClient } from "./client";
import type { TmdbTimeWindow } from "./types";

export const tmdbApi = createApi({
  reducerPath: "tmdbApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  endpoints: (builder) => ({
    // MARK: Movies

    getMovie: builder.query({
      queryFn: async ({ id, language }: { id: number; language: string }) => {
        try {
          return { data: await tmdbClient.getMovie(id, language) };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
    getTrendingMovies: builder.query({
      queryFn: async ({
        timeWindow,
        language,
      }: {
        timeWindow: TmdbTimeWindow;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.getTrendingMovies(timeWindow, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
    searchMovies: builder.query({
      queryFn: async ({
        query,
        language,
      }: {
        query: string;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.searchMovies(query, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
    getPopularMovies: builder.query({
      queryFn: async ({
        page,
        region,
        language,
      }: {
        page: number;
        region?: string;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.getPopularMovies({ page, region }, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),

    // MARK: TV Series

    getTVSeries: builder.query({
      queryFn: async ({ id, language }: { id: number; language: string }) => {
        try {
          return { data: await tmdbClient.getTVSeries(id, language) };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
    getTrendingTVSeries: builder.query({
      queryFn: async ({
        timeWindow,
        language,
      }: {
        timeWindow: TmdbTimeWindow;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.getTrendingTVSeries(timeWindow, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
    searchTVSeries: builder.query({
      queryFn: async ({
        query,
        language,
      }: {
        query: string;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.searchTVSeries(query, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
    getPopularTVSeries: builder.query({
      queryFn: async ({
        page,
        region,
        language,
      }: {
        page: number;
        region?: string;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.getPopularTVSeries(
              { page, region },
              language,
            ),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),

    // MARK: Genres
    getMoviesGenres: builder.query({
      queryFn: async ({ language }: { language: string }) => {
        try {
          return {
            data: await tmdbClient.getMoviesGenres({ language }),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),

    getSeriesGenres: builder.query({
      queryFn: async ({ language }: { language: string }) => {
        try {
          return {
            data: await tmdbClient.getSeriesGenres({ language }),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),

    // MARK: Search Multi
    searchMulti: builder.query({
      queryFn: async ({
        query,
        page,
        language,
      }: {
        query: string;
        page: number;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.searchMulti({ query, page }, language),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),

    // MARK: Discover
    getDiscoverMovies: builder.query({
      queryFn: async ({
        with_genres,
        page,
        language,
      }: {
        page?: number;
        with_genres?: string;
        language: string;
      }) => {
        try {
          const data = await tmdbClient.discoverMovies({
            with_genres,
            page,
            language,
          });

          return {
            data: data,
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}${queryArgs.with_genres || ""}`;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      merge(currentCacheData, responseData) {
        if (!currentCacheData || !responseData) {
          return;
        }

        currentCacheData.results.push(...responseData.results);
      },
    }),

    getDiscoverSeries: builder.query({
      queryFn: async ({
        with_genres,
        page,
        language,
      }: {
        page?: number;
        with_genres?: string;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.discoverSeries({
              with_genres,
              page,
              language,
            }),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}${queryArgs.with_genres || ""}`;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      merge(currentCacheData, responseData) {
        if (!currentCacheData || !responseData) {
          return;
        }

        currentCacheData.results.push(...responseData.results);
      },
    }),

    // MARK: Related
    getRelatedMovies: builder.query({
      queryFn: async ({
        movieId,
        page,
        language,
      }: {
        movieId: number;
        page: number;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.getRelatedMovies({
              movieId,
              page,
              language,
            }),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),

    getRelatedSeries: builder.query({
      queryFn: async ({
        seriesId,
        page,
        language,
      }: {
        seriesId: number;
        page: number;
        language: string;
      }) => {
        try {
          return {
            data: await tmdbClient.getRelatedSeries({
              seriesId,
              page,
              language,
            }),
          };
        } catch (err) {
          return {
            error: { status: 400, data: new Error("", { cause: err }) },
          };
        }
      },
    }),
  }),
});

export const {
  useGetMovieQuery,
  useGetTrendingMoviesQuery,
  useSearchMoviesQuery,
  useGetPopularMoviesQuery,
  useGetTVSeriesQuery,
  useGetTrendingTVSeriesQuery,
  useSearchTVSeriesQuery,
  useGetPopularTVSeriesQuery,
  useSearchMultiQuery,
  useGetRelatedMoviesQuery,
  useGetRelatedSeriesQuery,
  useGetMoviesGenresQuery,
  useGetSeriesGenresQuery,
  useGetDiscoverMoviesQuery,
  useGetDiscoverSeriesQuery,
} = tmdbApi;
